import React from "react";
import Img_Block2 from '../assets/images/Block2.webp';

export const Block2 = (props) => {
  return (
    <section class="bg-zinc-800">
      <div className="w-full h-screen bg-right bg-cover rounded-t-my custom-img-white">
        <article id="about">

          <div className="container mx-auto pb-20 pt-20 max-w-xs md:max-w-7xl ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="pt-40 lg:pt-52 mr-auto">
                <div class="mr-auto place-self-center">
                  <h1 class="title211 block__h1 my-0 text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-7xl leading-none">Уникальный</h1>
                  <h1 class="title211 block__h2 my-0 text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-7xl leading-none">Уникальный</h1>
                  <h1 class="hero__h3 mb-4 mt-0 text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-7xl leading-none">Уникальный</h1>
                  <p class="mb-6 lg:mb-8 uppercase font-extrabold text-lg md:text-5xl text-zinc-800"><span className="text-orange-400">дизайн-проект</span> по невероятной цене</p>
                  <ul class="space-y-4 max-w-md text-left text-gray-500 dark:text-gray-400">
                    <li class="flex justify-between items-center space-x-3 text-3xl">
                      <span>Студия</span><span class="text-gray-900">30 000 ₽</span>
                    </li>
                    <li class="flex justify-between items-center space-x-3 text-3xl">
                      <span>1 комнатная</span><span class="text-gray-900">45 000 ₽</span>
                    </li>
                    <li class="flex justify-between items-center space-x-3 text-3xl">
                      <span>2 комнатная</span><span class="text-gray-900">60 000 ₽</span>
                    </li>
                    <li class="flex justify-between items-center space-x-3 text-3xl">
                      <span>3 комнатная</span><span class="text-gray-900">75 000 ₽</span>
                    </li>
                  </ul>

                </div>
              </div>
              <div class="hidden md:flex mr-auto place-self-center px-5 pt-40 lg:pt-52 mr-auto">
                <img src={Img_Block2} />
              </div>
            </div>
          </div>
        </article >
      </div>
    </section>
  );
};
