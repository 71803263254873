import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_oufdqkw', 'template_bwuv7xx', form.current, 'OxOKaM4VoT-_hEl59')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <form ref={form} onSubmit={sendEmail} class="grid grid-cols-1 gap-4 p-5">
            <div>
                <label class="block mb-2">Ф.И.О</label>
                <input type="text" name="user_name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required />
            </div>
            <div>
                <label class="block mb-2">Телефон</label>
                <input type="text" name="user_tel" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Ваш телефон" required />
            </div>
            <div>
                <label class="block mb-2">Email</label>
                <input type="email" name="user_email" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Ваш адрес E-mail" required />
            </div>
            <div>
                <label for="large" class="block mb-2">Округ г. Москвы в котором вы проживаете</label>
                <select id="default" name="okrug" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light">
                    <option selected>Выбрать округ</option>
                    <option value="ЦАО">Центральный</option>
                    <option value="САО">Северный</option>
                    <option value="СВАО">Северо-Восточный</option>
                    <option value="ВАО">Восточный</option>
                    <option value="ЮВАО">Юго-Восточный</option>
                    <option value="ЮАО">Южный</option>
                    <option value="ЮЗАО">Юго-Западный</option>
                    <option value="ЗАО">Западный</option>
                    <option value="СЗАО">Северо-Западный</option>
                    <option value="ЗЕЛАО">Зеленоградский</option>
                    <option value="ТИНАО">Троицкий</option>
                    <option value="ТИНАО">Новомосковский</option>
                    <option value="Другое">другое ...</option>
                </select>
            </div>
            <div>
                <label for="large" class="block mb-2">Откуда узнали о нас</label>
                <select id="default" name="where" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light">
                    <option selected>Выбрать источник</option>
                    <option value="int">Реклама в интернете</option>
                    <option value="tel">Реклама в телеграмм</option>
                    <option value="map">Карты</option>
                    <option value="req">Рекомендация друзей/знакомых</option>
                    <option value="lpole">Сайт люблинское поле</option>
                    <option value="Другое">другое ...</option>
                </select>
            </div>
            <input type="submit" value="Отправить" class="w-full mt-5 focus:outline-none text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900" />
        </form>
    );
};