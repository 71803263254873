import React, { useRef, useEffect } from "react";
import ContactUs from "./ContactUs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

export const Hero = () => {
  // ссылка на элемент
  const el = useRef()

  // запускаем `gsap` после рендеринга
  useEffect(() => {
    gsap.to(el.current, { opacity: 1, duration: 1, delay: 1, x: 10 });
  })

  const circleRef = useRef(null);
  useEffect(() => {
    gsap.to("#thirdCircle", {
      x: 10,
      y: 100,
      duration: 5,
      opacity: 0,
      scrollTrigger: {
        trigger: "#thirdCircle",
        start: "top top",
        end: "bottom 70px",
        scrub: true,
      }
    });
  }, []);

  return (
    <section class="bg-zinc-800">
      <div className="w-full h-screen bg-right bg-cover rounded-t-my custom-img-1">
        <div class="mx-auto max-w-xs sm:max-w-sm md:max-w-6xl lg:max-w-7xl py-8 overflow-hidden" id="thirdCircle">
          <div class="pt-40 lg:pt-72 mr-auto place-self-center opacity-0" ref={el}>
            <h1 class="title1 hero__h1 my-0 max-w-6xl text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-8xl leading-none">VR прогулка</h1>
            <h1 class="title1 hero__h2 my-0 max-w-6xl text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-8xl leading-none">VR прогулка</h1>
            <div className="glitch">
              <h1 class="max-w-6xl hero__h3 mb-4 text-3xl text-black font-extrabold tracking-tight uppercase md:text-5xl xl:text-8xl leading-none">VR прогулка</h1>
            </div>
            <p class="max-w-6xl text-black uppercase font-extrabold text-lg md:text-5xl text-gray-400">По вашей новой квартире<br />Бесплатно для наших покупателей</p>
          </div>
        </div>
        <ContactUs />
      </div>
    </section>
  );
};
