import React from "react";

export const Contacts = () => {
  return (
    <section id="contacts">
      <div
        class="container mx-auto max-w-xs md:max-w-7xl py-8 text-2xl text-center py-12 sm:py-24"
      >
        <h2 className="mavka text-4xl md:text-6xl font-bold mb-4">
          Как нас найти
        </h2>
      </div>
      <div class="container mx-auto max-w-xs md:max-w-7xl py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <div className="">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A3ea28179684e655edc3e15af41fbb74c4a148d4861bf849f7b2a5287291b0298&amp;source=constructor" width="100%" height="400px" frameborder="0"></iframe>
          </div>
          <div className="px-10">

            <h3 className="text-2xl font-bold tracking-tight md:text-4xl">ТЦ «Люблинское поле»</h3>
            <br />
            <p><b>Адрес:</b> г. Москва, Тихорецкий б-р. 1, стр.2а<br />(м. Люблино)</p><br />
            <p>Ежедневно с 9:00 до 20:00</p><br />
            <p><b>E-mail:</b> studio@lpole.ru</p><br />
            <p><b>Телефон:</b> +7 (495) 785-34-34</p>
            <p><b>Телефон:</b> +7 (499) 390-10-60</p>
            <br />
            <p><b>Сайт:</b> www.lpole.ru</p>

          </div>
        </div>
      </div>
    </section >
  );
};

