import React, { Component } from "react";

import { Navigate, Link } from "react-router-dom";


import { variables, showDate } from "../Variables.js";
import { Topbar } from "../../components/Topbar";

function GetHistogramData(x_data) {
  var trace1 = [
    //    {
    //      histnorm: "percent",
    //      x: x_data,
    //      mode: 'markers',
    //      type: 'histogram',
    //      name: 'Percents',
    //
    //      marker: { size: 8 },
    //    },
    {
      x: x_data,
      mode: "markers",
      type: "histogram",
      name: "Count",

      marker: { size: 8 },
    },
  ];

  var data = trace1;

  return data;
}

function GetHistogramLayout(x_label) {
  console.log(x_label);
  var layout = {
    title: x_label,
    width: 476,
    height: 476,
  };

  return layout;
}

export class Statistic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: variables.user,
      token: variables.token,
      uploaded_file: variables.uploaded_file,
      info: [],
      elemPerPage: 0,
      currentElem: null,
      moreDetails: false,
    };
  }

  // Загрузка датасета

  uploadClick = (e) => {
    e.preventDefault();
    let format = e.target.files[0].name.split(".").slice(-1)[0];
    console.log(format);
    if (format != "csv" && format != "xlsx") {
      alert('Загрузите пожайлуста файлы формата ".csv" или ".xlsx"');
      return;
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "main/datasets/upload", {
      headers: {
        Authorization: `Token ${this.state.token}`,
      },
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status == 201) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        console.log(data);
        this.setState({ uploaded_file: data.name });
        variables.uploaded_file = data.name;
        this.LoadDataset(data.name);
      })
      .catch((error) => {
        alert("Ошибка");
      });
  };

  // Подгрзка датасета с сервера и его визуализация

  LoadDataset(datasetName) {
    if (datasetName == null) {
      return;
    }
    fetch(
      variables.API_URL +
      "main/dataset/statistic" +
      `?datasetName=${datasetName}`,
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Token ${this.state.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          info: data.data,
          currentElem: data.data[0],
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ info: [] });
      });
  }

  componentDidMount() {
    this.LoadDataset(this.state.uploaded_file);
  }

  handleElem(move_dir) {
    if (this.state.info.length == 0) {
      return;
    }

    var nextElem = this.state.elemPerPage;
    if (move_dir == 1) {
      if (nextElem == this.state.info.length - 1) nextElem = 0;
      else nextElem += 1;
    } else {
      if (nextElem == 0) nextElem = this.state.info.length - 1;
      else nextElem -= 1;
    }
    this.setState({
      elemPerPage: nextElem,
      moreDetails: false,
      currentElem: this.state.info[nextElem],
    });
  }
  selectColumn = (e) => {
    this.setState({ currentElem: e });
  };

  render() {
    const {
      token,
      user,
      uploaded_file,
      info,
      elemPerPage,
      currentElem,
      moreDetails,
    } = this.state;

    if (token == "") {
      return <Navigate push to="/" />;
    } else {
      return (
        <div>
          {/* Подгружаем боковую панель */}
          <div>
            <Topbar />
          </div>
          {/* Начало основного раздела где выводим информацию о столбце */}
          <main class="p-4 md:ml-64 h-auto">
            <div class="absolute inset-0 -z-10 mx-0 max-w-none overflow-hidden">
              <div class="absolute left-1/2 top-0 ml-[-38rem] h-[25rem] w-[81.25rem] dark:[mask-image:linear-gradient(white,transparent)]">
                <div class="absolute inset-0 bg-gradient-to-r from-[#2590EB] to-[#2563EB] opacity-40 [mask-image:radial-gradient(farthest-side_at_top,white,transparent)] dark:from-[#36b49f]/30 dark:to-[#DBFF75]/30 dark:opacity-100">
                  <svg
                    aria-hidden="true"
                    class="absolute inset-x-0 inset-y-[-50%] h-[200%] w-full skew-y-[-18deg] fill-black/40 stroke-black/50 mix-blend-overlay dark:fill-white/2.5 dark:stroke-white/5"
                  >
                    <defs>
                      <pattern
                        id=":rai:"
                        width="72"
                        height="56"
                        patternUnits="userSpaceOnUse"
                        x="-12"
                        y="4"
                      >
                        <path d="M.5 56V.5H72" fill="none"></path>
                      </pattern>
                    </defs>
                    <rect
                      width="100%"
                      height="100%"
                      stroke-width="0"
                      fill="url(#:rai:)"
                    ></rect>
                    <svg x="-12" y="4" class="overflow-visible">
                      <rect
                        stroke-width="0"
                        width="73"
                        height="57"
                        x="288"
                        y="168"
                      ></rect>
                      <rect
                        stroke-width="0"
                        width="73"
                        height="57"
                        x="144"
                        y="56"
                      ></rect>
                      <rect
                        stroke-width="0"
                        width="73"
                        height="57"
                        x="504"
                        y="168"
                      ></rect>
                      <rect
                        stroke-width="0"
                        width="73"
                        height="57"
                        x="720"
                        y="336"
                      ></rect>
                    </svg>
                  </svg>
                </div>
                <svg
                  viewBox="0 0 1113 440"
                  aria-hidden="true"
                  class="absolute left-1/2 top-0 ml-[-19rem] w-[69.5625rem] fill-gray-200 blur-[26px] dark:hidden"
                >
                  <path d="M.016 439.5s-9.5-300 434-300S882.516 20 882.516 20V0h230.004v439.5H.016Z"></path>
                </svg>
              </div>
            </div>
            <div class="px-4 pt-4">
              <div class="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8">
                <div class="flex flex-wrap justify-between items-center dark:border-gray-600 mb-4">
                  <h2 class="flex text-lg font-semibold text-gray-900 dark:text-white">
                    Image with CTA button
                  </h2>
                  <div class="flex items-end"></div>
                  <section class="bg-white dark:bg-gray-900">
                    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        class="w-full dark:hidden"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup.svg"
                        alt="dashboard image"
                      />
                      <img
                        class="w-full hidden dark:block"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup-dark.svg"
                        alt="dashboard image"
                      />
                      <div class="mt-4 md:mt-0">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                          Let's create more tools and ideas that brings us
                          together.
                        </h2>
                        <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                          Flowbite helps you connect with friends and
                          communities of people who share your interests.
                          Connecting with your friends and family as well as
                          discovering new ones is easy with features like
                          Groups.
                        </p>
                        <a
                          href="#"
                          class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
                        >
                          Get started
                          <svg
                            class="ml-2 -mr-1 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div class="mx-4 mt-4">
              <footer class="w-full rounded-lg bg-white shadow dark:bg-gray-800 md:flex md:items-center md:justify-between w-full p-6">
                <div class="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0">
                  <ul
                    data-testid="footer-groupLink"
                    class="flex flex-wrap text-sm text-gray-500 dark:text-white"
                  >
                    <li class="last:mr-0 md:mr-6 mr-3">
                      <a href="#" class="hover:underline">
                        Cookie Policy
                      </a>
                    </li>
                    <li class="last:mr-0 md:mr-6">
                      <a href="#" class="hover:underline">
                        Контакты
                      </a>
                    </li>
                  </ul>
                  <ul
                    data-testid="footer-groupLink"
                    class="flex flex-wrap text-sm text-gray-500 dark:text-white"
                  >
                    <div class="flex gap-x-1">
                      <li class="last:mr-0 md:mr-6 hover:[&amp;>*]:text-black dark:hover:[&amp;>*]:text-gray-300">
                        <a href="#" class="hover:underline">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 496 512"
                            class="text-lg"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path>
                          </svg>
                        </a>
                      </li>
                      <li class="last:mr-0 md:mr-6 hover:[&amp;>*]:text-black dark:hover:[&amp;>*]:text-gray-300">
                        <a href="#" class="hover:underline">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            class="text-lg"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"></path>
                          </svg>
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              </footer>
              <p class="my-8 text-center text-sm text-gray-500 dark:text-gray-300">
                © 2016-2023 datamed.pro
              </p>
            </div>
          </main>
        </div>
      );
    }
  }
}
