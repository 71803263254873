import { Header } from "../components/header";
import { Hero } from "../components/hero";
import { Prebout } from "../components/prebout";
import { Block2 } from "../components/Block2";
import { Block3 } from "../components/Block3";
import { Block1 } from "../components/Block1";
import { Contacts } from "../components/contacts";
import { Block } from "../components/block";
import { Footer } from "../components/footer";

import SmoothScroll from "smooth-scroll";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Main = () => {
  return (
    <>
      <Header />
      <Block2 />
      <Block3 />

      <Block1 />
      <Hero />
      {/*<Prebout />*/}


      <Contacts />
      <Block />
      <Footer />
    </>
  );
};
export default Main;

