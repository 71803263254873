import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { variables } from '../api/Variables.js'
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { ReactComponent as ReactLogo } from "../assets/images/logo.svg";


//import Modal from "../components/Modal";

//import Switcher from "../components/Switcher";


const navigation = [
	{ name: 'Первый', href: '#', current: true },
	{ name: 'Второй', href: '#', current: false },
	{ name: 'Третий', href: '#', current: false },
	{ name: 'Четвёртый', href: '#', current: false },
	{ name: 'Пятый', href: '#', current: false },
]
const userNavigation = [
	{ name: 'Профиль', href: '/account' },
	{ name: 'Уведомления', href: '/messages' },
	{ name: 'Выйти', href: '/' },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export class Topbar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: variables.username,
			email: variables.email,
			imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
		}
	}

	componentDidMount() {
		this.setState({
			name: variables.username,
			email: variables.email
		});
	}

	render() {
		return (
			<>
				{/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
				<header>
					<Disclosure as="nav" className="bg-slate-50 border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 shadow">
						{({ open }) => (
							<>
								<div className="flex items-center justify-between">
									<div className="flex items-center">
										<a href="https://lpole.ru" class="flex items-start">
											<ReactLogo className="h-8 mr-3" alt="Lpole logo" />
										</a>
									</div>
									<div className="hidden md:block">
										<div className="ml-4 flex items-center md:ml-6">
											{/*<Modal */}
											{/*<Switcher />*/}
											<button
												type="button"
												className="rounded-full p-1 text-gray-400 hover:text-gray-900 dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
											>
												<span className="sr-only">Посмотреть сообщения</span>
												<BellIcon className="h-6 w-6" aria-hidden="true" />
											</button>

											{/* Profile dropdown */}
											<Menu as="div" className="relative ml-3">
												<div>
													<Menu.Button className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
														<span className="sr-only">Открыть меню пользователя</span>
														<img className="h-8 w-8 rounded-full" src={this.state.imageUrl} alt="" />
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-slate-50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:divide-gray-600">
														<div class="py-3 px-4">
															<span class="block text-sm font-semibold text-gray-900 dark:text-white">{this.state.name}</span>
															<span class="block text-sm font-light text-gray-500 truncate dark:text-gray-400">{this.state.email}</span>
														</div>
														{userNavigation.map((item) => (
															<><Menu.Item key={item.name}>
																{({ active }) => (
																	<Link to={item.href}>
																		<a
																			href="#"
																			className={classNames(
																				active ? 'bg-gray-100' : '',
																				'block px-4 py-2 text-sm text-gray-700'
																			)}
																		>
																			{item.name}
																		</a>

																	</Link>
																)}
															</Menu.Item>

															</>
														))}
													</Menu.Items>

												</Transition>
											</Menu>
										</div>
									</div>
									<div className="-mr-2 flex md:hidden">
										{/* Mobile menu button */}
										<Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
											<span className="sr-only">Открыть меню пользователя</span>
											{open ? (
												<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
											) : (
												<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
											)}
										</Disclosure.Button>
									</div >
								</div >

								<Disclosure.Panel className="md:hidden">
									<div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
										{navigation.map((item) => (
											<Disclosure.Button
												key={item.name}
												as="a"
												href={item.href}
												className={classNames(
													item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
													'block rounded-md px-3 py-2 text-base font-medium'
												)}
												aria-current={item.current ? 'page' : undefined}
											>
												{item.name}
											</Disclosure.Button>
										))}
									</div>
									<div className="border-t border-gray-700 pt-4 pb-3">
										<div className="flex items-center px-5">
											<div className="flex-shrink-0">
												<img className="h-10 w-10 rounded-full" src={this.state.imageUrl} alt="" />
											</div>
											<div className="ml-3">
												<div className="text-base font-medium leading-none text-white">{this.state.name}</div>
												<div className="text-sm font-medium leading-none text-gray-400">{this.state.email}</div>
											</div>
											<button
												type="button"
												className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
											>
												<span className="sr-only">Уведомления</span>
												<BellIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
										<div className="mt-3 space-y-1 px-2">
											{userNavigation.map((item) => (
												<ul class="space-y-2">
													<Link to={item.href}>
														<li>
															<a href="#" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
																<svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
																<span class="flex-1 ml-3 whitespace-nowrap">{item.name}</span>
																<span class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>
															</a>
														</li>
													</Link>
												</ul>
											))}
										</div>
									</div>
								</Disclosure.Panel>
							</>
						)
						}
					</Disclosure>
				</header >
			</>
		)
	}
}