import React from "react";
import { ReactComponent as ReactLogo } from "../assets/images/logo-white.svg";


export const Footer = () => {
  return (
    <footer class="bg-zinc-800">
      <div class="container mx-auto max-w-xs md:max-w-7xl py-8 text-white">
        <div class="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div>
            <div class="flex items-center">
              <a href="https://lpole.ru" class="flex items-start">
                <ReactLogo className="h-8 mr-3" alt="Lpole logo" />
              </a>
              <div class="ml-4">
                <p class="mt-1 text-sm">Создаём красивые окружения для людей, создающих красивый мир.</p>
              </div>
            </div>
            {/*
            <nav class="mt-11 flex gap-8">
              <a class="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]" href="/#features">
                <span class="relative z-10">О торговом центре</span>
              </a>
              <a class="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]" href="/#reviews">
                <span class="relative z-10">О программе</span>
              </a>
              <a class="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]" href="/#pricing">
                <span class="relative z-10">Преимущества</span>
              </a>
              <a class="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]" href="/#faqs">
                <span class="relative z-10">FAQs</span>
              </a>
            </nav>
            */}
          </div>

          {/*
          <div class="group relative -mx-4 flex items-center self-stretch p-4 transition-colors sm:self-auto sm:rounded-2xl lg:mx-0 lg:self-auto lg:p-6">
            <div class="relative flex h-24 w-24 flex-none items-center justify-center">
              <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" class="absolute inset-0 h-full w-full stroke-gray-300 transition-colors group-hover:stroke-orange-500">
                <path d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8" stroke-width="2" stroke-linecap="round"></path>
              </svg>
            </div>
            <div class="ml-8 lg:w-64">
              <p class="text-base font-semibold">
                <a href="/#"><span class="absolute inset-0 sm:rounded-2xl">
                </span>Загрузите мобтльное приложение</a>
              </p>
              <p class="mt-1 text-sm">Отсканируйте QR код для загрузки приложения из App Store.</p>
            </div>
          </div>*/}
        </div>
        <div class="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          <p class="mt-6 text-sm text-gray-500 md:mt-0">© 2023. ТЦ "Люблинское Поле"</p>
        </div>
      </div>
    </footer>
  );
};



