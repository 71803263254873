import "./index.css";
import "./input.css";

import { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Импорт страниц
import Main from "./pages/Main.jsx";
import { Login } from "./pages/Login.jsx";
import { Registration } from "./pages/Registation.jsx";
import { Viewer } from "./pages/Viewer.jsx";
import { Grafics } from "./api/Grafics/Grafics.js";
import { Statistic } from "./api/Statistic/Statistic.js";

function App() {
  useEffect(() => {
    // 👇️ adding multiple classes to the body element
    document.body.classList.add("bg-gray-100", "dark:bg-gray-900");
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<Registration />} />
        <Route path="/viewer" element={<Viewer />} />
        <Route path="/graphics" element={<Grafics />} />
        <Route path="/statistic" element={<Statistic />} />
      </Routes>
    </Router>
  );
}

export default App;
