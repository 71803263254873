import React from "react";

export const Block = () => {
  return (
    <article
      id="Block_1" class="mt-5 bg-slate-800"
    >
      <div
        className="bg-gray-100 rounded-b-my box-content h-16"
      >
      </div>
    </article>
  );
};

