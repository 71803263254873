import React from "react";
import Img_Block2 from '../assets/images/hero.webp';

export const Block1 = (props) => {
  return (
    <article id="about" className="bg-zinc-800">
      <div className="container mx-auto pb-20 pt-20 max-w-xs md:max-w-7xl ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="hidden md:block mr-auto place-self-center px-5">
            <img src={Img_Block2} />
          </div>
          <div class="mr-auto place-self-center text-white">
            <h1 class="title21 block__h1 my-0 text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-7xl leading-none">Что такое VR прогулка?</h1>
            <h1 class="title21 block__h2 my-0 text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-7xl leading-none">Что такое VR прогулка?</h1>
            <h1 class="hero__h3 mb-4 mt-0 text-3xl font-extrabold tracking-tight uppercase md:text-5xl xl:text-7xl leading-none">Что такое VR прогулка?</h1>
            <p class="mb-6 lg:mb-8 text-lg">Это виртуальный дизайн-проект вашей квартиры с возможностью перемещения по интерьеру и фотореалистичной передачей деталей!</p>
            <p class="mb-6 lg:mb-8 text-lg">Теперь у вас есть уникальная возможность создать и визуализировать ваш проект через очки виртуальной реальности в режиме реального времени.</p>
            <p class="mb-6 lg:mb-8 text-lg">Вы сможете передвигаться в интерьере, оценить дизайн в реальном размере, рассмотреть отделочные материалы со всех сторон в будущей обстановке. </p>
          </div>
        </div>
      </div>
    </article >
  );
};
