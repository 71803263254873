import React, { useRef, useEffect } from "react";

export const Block3 = () => {
  return (
    <article
      id="about"
      class="container mx-auto max-w-xs md:max-w-7xl mb-12 text-2xl py-12 sm:py-24"
    >
      <h1 class="py-12 text-4xl text-center font-extrabold tracking-tight leading-none text-zinc-800 md:text-5xl lg:text-6xl">Что входит в <span className="text-orange-400">дизайн-проект</span>?</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-zinc-800 sm:text-4xl">Планировочное решение</h3>
          <p className="mt-6 text-lg leading-8 text-gray-900">Архитектор подготовит несколько вариантов планировочного решения вашей квартиры, учитывая ваши пожелания</p>
          <ul className="mt-10 max-w-xl space-y-3 text-base leading-7 text-gray-600 lg:max-w-none">
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>Обмерный план</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>План демонтажа и монтажа перегородок</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>План растановки мебели</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-zinc-800 sm:text-4xl">3Д Визуализация/VR</h3>
          <p className="mt-6 text-lg leading-8 text-gray-900">Вы сможете увидеть свой интерьер не только на фотографиях, но и погрузитесь в него полностью! Надев специальные очки, вы сможете прогуляться по своей квартире и прочувствовать мельчайшие детали интерьера
          </p>
          <ul className="mt-10 max-w-xl space-y-3 text-base leading-7 text-gray-600 lg:max-w-none">
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>Фотореалистичные визуализации</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>Прогулка по квартире в 3D очках</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-zinc-800 sm:text-4xl">Альбом чертежей</h3>
          <p className="mt-6 text-lg leading-8 text-gray-900">Комплект документов для верной реализации проекта строителями</p>
          <ul className="mt-10 max-w-xl space-y-3 text-base leading-7 text-gray-600 lg:max-w-none">
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>План сантехники</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>План электровыводов</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>План потолка и напольного покрытия</span>
            </li>
            <li class="flex items-center space-x-3">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-orange-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
              </svg>
              <span>Развёртки по стенам с указанием типа отделки и всех декоративных элементов</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-zinc-800 sm:text-4xl">Ведомости и спецификации</h3>
          <p className="mt-6 text-lg leading-8 text-gray-900">Перечень всех отделочных материалов и товаров по выгодным ценам, которые вы сможете приобрести в нашем торговом центре.
            Наши дизайнеры соберут все необходимое и организуют доставку.
          </p>
        </div>
      </div>
    </article >
  );
};






